import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography, Card, CardContent, CardMedia, Avatar, Button} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {loadEventSuggestions} from "../../../client/events/call";

const BlogCard = ({category, titleSinhala, description, author, createdDate, thumbnail}) => (
    <div className={'animated-card-border'} >
        <Card sx={{margin: '0px auto', minHeight: '450px',}}>
            <CardMedia
                component="img"
                sx={{height: 240, }}
                image={thumbnail} // Placeholder for image
                title={titleSinhala}
            />
            <CardContent >
                <Typography variant="subtitle2" >
                    {category}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {titleSinhala}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {description}
                </Typography>
                <Box display="flex" alignItems="center" mt={2}>
                    <Avatar sx={{width: 24, height: 24, mr: 1}}/>
                    <Typography variant="body2">{author}</Typography>
                    <Typography variant="body2" sx={{mx: 1}}>
                        •
                    </Typography>
                    <Typography fontFamily={"roboto"} variant="body2">{createdDate}</Typography>
                    <Typography variant="body2" sx={{mx: 1}}>
                        •
                    </Typography>
                    <AccessTimeIcon fontSize="small" sx={{mr: 0.5}}/>
                    <Typography fontFamily={"roboto"} variant="body2">{"5 min"}</Typography>
                </Box>
            </CardContent>
        </Card>
    </div>
);

export const RecentEvents = () => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        loadEventSuggestions(result => {
                setEvents(result.content)
            },
            null,
            null,
            3
        );
    }, [])

    return (
        <Box p={2} textAlign="center"
             sx={{
                 width: '80%',
                 justifyContent: 'center',
                 margin: '50px auto',
             }}
        >
            <Typography variant="h4" gutterBottom>
                kj;u jHdmD;s yd isoaëka
            </Typography>
            <Typography variant="body1"  gutterBottom>
                wmf.a kj;u jHdmD;s" jevigyka yd l%shdldrlï .fõIKh lsÍu i|yd
            </Typography>

            <Grid sx={{justifyContent: "center", alignItems: "center",}} container spacing={3} justifyContent="center" mt={2}>
                {events &&  events.map((event, index) => (
                    <Grid sx={{margin: '0px auto', justifyContent: 'center'}} item xs={12} sm={6} md={4} key={index}>
                        <BlogCard {...event} />
                    </Grid>
                ))}
            </Grid>

            <Box mt={4}>
                <Button variant="outlined">ish,a, keröu i|yd'''</Button>
            </Box>
        </Box>
    );
};
