import './assets/css/App.css';
import * as React from "react";

import MainLayout from "./components/ui/layout/mainLayout";

import AOS from 'aos'
import 'aos/dist/aos.css';
import {useEffect, useState} from "react";
import pasadikaLogo from "./assets/images/pasadika-logo-giff.gif";
import {Box, Grid, LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

AOS.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 2000, // values from 0 to 3000, with step 50ms
    easing: 'ease-in', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: true, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} sx={{
                    height: 10,
                    borderRadius: 5,
                }}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', fontFamily: 'Roboto' }}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function App() {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 9000)
    }, [])

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            {isLoading ? <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: '#fcf6ea',
                        position: 'fixed',
                        zIndex: '200',
                        display: isLoading ? 'flex' : 'none',
                    }}
                >
                    <Grid className={'preloader'} direction="row" container spacing={2} sx={{
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: '500px',
                    }}>
                        <Grid item md={12}>
                            <img  src={pasadikaLogo} alt="logo" width="500px"/>
                        </Grid>
                        <Grid item md={12}>
                            <LinearProgressWithLabel value={progress} />
                        </Grid>
                    </Grid>

                </Box> :
                <div className="App">
                    <MainLayout/>
                </div>}
        </>
    );
}

export default App;
