import {forwardRef, useMemo} from "react";
import {Link as RouterLink} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as React from "react";

export const ListItemLink = (props) => {
    const {icon, primary, to, isMain} = props;

    const renderLink = useMemo(
        () =>
            forwardRef(function Link(itemProps, ref) {
                return <RouterLink to={to} ref={ref} {...itemProps} role={undefined}/>;
            }),
        [to],
    );
    return (
        <li style={{padding: 0, margin: 'auto'}}>
            <ListItemButton
                sx={{ margin: "auto"}}
                selected={false}
                onClick={(event) => {
                }}
                component={renderLink}
            >
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <p style={{color: isMain ? 'white': 'inherit', fontFamily: 'FMMalithi', margin: 'auto'}} >{primary}</p>
            </ListItemButton>
        </li>
    );
}