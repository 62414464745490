import React from 'react';
import {Container, Typography, Button, Box, CardMedia} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {API_CONFIGS} from "../../../config";
import {useNavigate} from "react-router-dom";

const events = [
    {
        id:'6104261a5307a052e079e410',
        year: '2015',
        title: 'mdidÈl NslaIq jdÜgq ixlS¾Kh',
        description: 'mdidÈl NslaIq jdÜgq ixlS¾Kh yd fn!oaO uOHia:dkh bÈfldg iïnqoaO Ydikh yd uyd ix>r;akh Wfoid mQcd lsÍu',
    },
    {
        id:'610426375307a052e079e411',
        year: '2015',
        title: 'ñßijeáh yd  fÊ;jkdru msxlu',
        description: 'Our first charity event took place in 2010, bringing together community members to support those in need.',
    },
    {
        id:'6104261a5307a052e079e410',
        year: '2015',
        title: 'International Outreach Program',
        description: 'In 2015, we launched our International Outreach Program, extending our mission beyond Sri Lanka.',
    },
    {
        id:'6104261a5307a052e079e410',
        year: '2018',
        title: 'j,a.u NslaIq wdhq¾fõo frday,',
        description: 'j,a.u NslaIq wdhq¾fõo frday, bÈlsÍu yd uyd ix>r;akh fjkqfjka mQcd lsÍu',
    },
];
const mainContainerStyle = {
    width: '80%',
    margin: '50px auto',
    borderRadius: '15px',
    padding: '50px',
    backgroundColor: 'background.container'
}
export const KeyEvents = () => {
    const navigate = useNavigate()
    const handleOnClick = (id) => {
        navigate(`/events/${id}`)
    }//sx={{ marginTop: 4, marginBottom: 4, backgroundColor: "#F2EDE4", borderRadius: "15px" }}
    return (
        <Container maxWidth="xl" sx={mainContainerStyle}>
            <Box textAlign="center" mb={4} >
                <Typography variant='h3' gutterBottom >
                    jeo.;a jHdmD;s yd jevigyka
                </Typography>
                <Typography variant='body1'>
                    fuf;la mdidÈl .ukau. ;=, isÿ jQ jeo.;a jHdmD;s yd jevigyka
                </Typography>
            </Box>
            <Timeline position="alternate">
                {events.map((event, index,) => (
                    <TimelineItem sx={{minHeight: '300px'}} key={index}>
                        <TimelineOppositeContent>
                            <Typography variant="h5">
                                {event.year}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant="filled"/>
                            <TimelineConnector sx={{ backgroundColor: 'cardBorderColor', }} />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Box sx={{ padding: 2, }}>
                                <Typography variant="h5">
                                    {event.title}
                                </Typography>
                                <CardMedia
                                    sx={{height: 210}}
                                    image={API_CONFIGS.fileBaseUrl + '' + event.thumbnail}
                                    title="green iguana"
                                />

                                {/*<Box sx={{backgroundColor: "#ffe3b0", height: '300px', borderRadius: "15px", margin: "10px 0px"}}>*/}

                                {/*</Box>*/}
                                <Typography variant="body2" >
                                    {event.description}
                                </Typography>
                                <Button
                                    variant="outlined" style={{marginTop:"35px"}} onClick={()=>handleOnClick(event.id)}>
                                    ;jÿrg;a lshjkak'''
                                </Button>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Container>
    );
};
