import {Box, Typography} from "@mui/material";

export const KarapitiyaNightViewCta = () => {
    const containerStyle = {
        backgroundOpacity: 0,
        minWidth: "100%",
        minHeight: "50vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 0,
        padding: 0,
    }
    const typographyStyle = {
        fontFamily: "FMBasuru",
        fontsize: '5rem',
        textShadow:`1px 1px 10px black, 1px 1px 20px black`
    }

    const gathaContainerStyle = {
        color: "gold",
    }
    const meaningContainerStyle = {
        color: 'white'
    }
    return (
        <Box data-aos="fade-up" sx={containerStyle}>
            <Box justifyContent="center">
                <Box sx={gathaContainerStyle}>
                    <Typography style={{fontSize: '2rem', color: 'gold'}} sx={typographyStyle}>
                        Wolx ys khk;s fk;;sld -
                        Wiqldrd kuhk;s f;ckx
                        <br/>
                        odrex kuhk;s ;pPld -
                        w;;dkx ouhk;s mKä;d
                    </Typography>
                </Box>
                <Box sx={meaningContainerStyle}>
                    <Typography style={{fontSize: '1.2rem', color: '#fff5da', fontFamily: 'FMMalithi', fontWeight: 'bold'}} sx={typographyStyle}>l=Uqre wdÈhg c,h f.kshk ;eke;af;da
                        ;uka leu;s ;eklg ^w.,a u.ska Èh f.kh;a&" <br/>
                        B idokafkda ;uka leu;s fia B;, ilia lr;a <br/>
                        jvqfjda ;uka leu;s mßÈ ,S ku;a" <br/> kqjKe;af;da
                        ;=uQu ;uka yslaujd .ks;a" fyj;a ;ukaf.a is;
                        oukh lr;a
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}