import React from 'react';
import {Box, Typography, Grid, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/system';
import introduction01 from '../../../assets/images/home/introduction/introduction-image.png';

const IntroductionSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                backgroundColor: '#fffcee',
                padding: isMobile ? '24px' : '100px',
                justifyContent: 'center',
                borderTop: '1px solid #f9f0f0',
                borderBottom: '1px solid #f9f0f0',
            }}
        >
            <Grid container spacing={4} alignItems="center">
                {/* Image Placeholder */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: isMobile ? '200px' : '300px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                        }}
                    >
                        <img style={{
                            width: '40vw', borderRadius: '15px',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        }}
                             src={introduction01} alt={""}/>
                    </Box>
                </Grid>

                {/* Text Content */}
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: '16px',
                                width: '50vw'
                            }}
                        >
                            uiei yd ukei úksúo keKei iam¾Y lsÍu Wfoid '''
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                lineHeight: 1.6,
                                textAlign: "justify",
                                marginBottom: '10px',
                            }}
                        >
                            <span className='highlighted-text' >iemj;a uki hkq </span>{`
                            ;ukag ys;lr" wkqkag ys;lr" bkak ;ekg ys;lr" fuf,djg ys;lr" mrf,djg ys;lr" kqjKe;a;kaf.a .eryqug ,la fkdjk whqßka iSñ; jQ iïm;a Wmßu ld¾hlaIu;djhla ,efnk mßÈ yd;amiska úu¾IKhg fldg ksjerÈj ie,iqï lr hï lghq;a;la id¾:lj l%shd;aul lr iEu md¾Yajhlgu hym;la jk whqßka tu lghq;a; ksjerÈj ksud lsÍfuka miqj ;uka l, lghq;a; .ek kej; úuid n,d ;ud w;ska yefudagu hym;la jQ njg wjfndaOhlska hq;=j ys;g we;s jk Woafõ.lr fkdjk iekis,s odhl mskd hEuhs' 
                            `}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                lineHeight: 1.6,
                                textAlign: "justify",
                            }}
                        >
                            {`tjka m%idokSh udkisl iajNdjhla ;j;a mqoa.,fhl=f.a yojf;ys cks; lrùfï wruqK uqLH mrud¾:h fldgf.k`} <span className='highlighted-text2' >mdidÈl fikiqka yd mokï </span> {`oduh ks¾udKh ù  we;'`}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default IntroductionSection;