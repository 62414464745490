import React from "react";
import { Box, Container, Grid, Typography, Card, CardContent, CardMedia, Avatar, Button } from "@mui/material";

const mainContainerStyle = {
    width: '80%',
    margin: '50px auto',
    borderRadius: '10px',
    padding: '50px',
    backgroundColor: 'background.container'
}

const NewsCard = ({ title, description, author, date, type }) => (
    <div className={'animated-card-border'} >
        <Card>
            <CardMedia
                component="div"
                sx={{height: 140, display: "flex", alignItems: "center", justifyContent: "center"}}
            >
                <Box sx={{width: 64, height: 64, borderRadius: "50%"}}/>
            </CardMedia>
            <CardContent>
                <Typography variant="subtitle2" gutterBottom>
                    {type}
                </Typography>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {description}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center" gap={1} mt={2}>
                    <Avatar sx={{width: 24, height: 24}}>{author[0]}</Avatar>
                    <Typography variant="caption">
                        {author} • {date} • 5 min read
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    </div>
        );

        export const NewsUpdatesSection = () => (
        <Box sx={mainContainerStyle}>
            <Container maxWidth="lg">
                <Typography variant="h3" >kj;u mdidÈl mqj;a yd f;dr;=re</Typography>
                <Typography
                    variant="body1" sx={{marginTop:"15px"}}>{`mdidÈl wmf.a kj;u l%shdldrlï iy uq,msÍï ms<sn|j oekqj;aj isákak`}</Typography>
                <Grid container spacing={4} sx={{
                    justifyContent: "center",
                    alignItems: "center",}}>
                    <Grid item xs={12} md={6} lg={4}>
                        <NewsCard
                            type="bbbbbbbbbbbbb"
                            title="Community Outreach Program Launch"
                            description="Join us in our new initiative to support local communities."
                            author="John Doe"
                            date="11 Jan 2022"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <NewsCard
                            type="Update"
                            title="Annual Charity Fundraiser Success"
                            description="Thank you for your generous support during our fundraiser!"
                            author="Jane Smith"
                            date="11 Jan 2022"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <NewsCard
                            type="News"
                            title="Volunteer Opportunities Available"
                            description="Become a part of our mission and make a difference."
                            author="Emily White"
                            date="11 Jan 2022"
                        />
                    </Grid>
                </Grid>
                <Button variant="outlined" size="medium" sx={{marginTop: "1rem", margin: "30px auto 0px auto",}}
                >ish,a, keröu i|yd'''</Button>
            </Container>
        </Box>
        );
