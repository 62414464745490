import * as React from 'react';
import {Dialog,} from '@mui/material';


export default function MainModalDialog(props) {

    const handleClose = () => {
        props.handleClose(false);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullWidth
                maxWidth={props.size?props.size:'sm'}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
            >
                {props.body}
            </Dialog>
        </div>
    );
}