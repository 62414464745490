import React, {useContext} from 'react';
import {Box, Button, Typography, Grid, CardContent, Card, CardMedia, CardActions} from '@mui/material';
import introduction01 from '../../../assets/images/home/introduction/welcome-section-image-01.jpg';
import introduction02 from '../../../assets/images/home/introduction/welcome-section-image-02.jpg';
import {AppContext} from "../../../context/appContext";
import {FoundationHome} from "./foundation/foundationHome";

export const AboutSection = () => {

    const {handleModalOpenClose, handleModalContent} = useContext(AppContext)
    const modelContent = <FoundationHome/>

    const mainContainerStyle = {
        width: '80%',
        margin: '50px auto',
        borderRadius: '10px',
        padding: '50px',
        backgroundColor: 'background.container'
    }
    const cardStyle = {
        minHeight: '600px',
        margin: 'auto',
    }
    const cardMediaStyle = {
        minHeight: 250,
    }
    const cardActionsStyle = {
        margin: 'auto',
        justifyContent: 'center'
    }
    const cardActionButtonStyle = {
        fontFamily: 'FMMalithi'
    }
    const cardContentDescriptionStyle = {
        textAlign: 'justify',
        fontSize: '1rem',
        lineHeight: '1.7'
    }

    return (
        <Box sx={mainContainerStyle}>
            <Grid sx={{margin: '0px auto 50px auto', justifyContent: "center",}} container>
                <Grid item xs={12} md={4}>
                    <Typography variant="h3">
                        mdidÈl iqixfhda.h
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography sx={{textAlign: 'justify',}} variant="body1">
                        {`f,!lsl fukau f,daflda;a;r WNh mrud¾: bgq lr .ekSu ms‚i wjYH jk`} <span className={'highlighted-text'}> iemj;a ukila </span> {`;ud ;=,ska u ÈhqKq lr
                    .ekSug wjYH jk kHdhd;aul yd m%dfhda.sl oekqu" wjfndaOd;aulj ÈhqKq lsÍu ;=,ska mßmQ¾K Ôú;hla idlaId;a
                    lr .ekSug wjYH mßir moaO;shla ìys lr wkd.;hg odhdo lsÍu' lsÍu fjkqfjka mdidÈl fikiqka oduh;a" mokï oduh;a ta hgf;a l%shd;aul jk úúO jHdmD;s yd jevigka fm<;a fuf,iska taldnoaO ù we;'`}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container sx={{justifyContent: "space-evenly",}} spacing={10}>
                <Grid item xs={12} md={6} xl={4}>
                    <div className={'animated-card-border'}>
                        <Card sx={cardStyle}>
                            <CardMedia
                                sx={cardMediaStyle}
                                image={introduction01}
                                title="green iguana"
                            />

                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {'mdidÈl fikiqka oduh'}
                                </Typography>
                                <Typography variant="body2" sx={cardContentDescriptionStyle}>
                                    {`hful= p;= i;Hh hkq l=ulaoehs ;:d.;hka jykafiaf.a b.ekaùug wkqj ksjerÈj u y÷kd f.k tu 
                                i;HdjfndaOh ms‚i W;aiql jkafka kï ta W;=ukag muKla uyK oï mqrkafka hhs lsj hq;= fjhs'
                                 tf,i uyKoï mqrkd NslaIQka jykafia,d msßila yd Ydiksl fiajdjka isÿ lrñka uyKoï mQrKh 
                                 i|yd fmryqrejla ,nñka isák NslaIQka jykafia,d msßil f.ka iukaú; jQ" m%foaY lsysmhl 
                                 úisr mj;skd  fikiqka oduhl tl;=jlska mdidÈl fikiqka moaO;sh iukaú; fõ`}
                                </Typography>
                            </CardContent>
                            <CardActions sx={cardActionsStyle}>
                                <Button size="small" sx={cardActionButtonStyle}>;jÿrg;a'''</Button>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <div className={'animated-card-border'}>
                        <Card sx={cardStyle}>
                            <CardMedia
                                sx={cardMediaStyle}
                                image={introduction02}
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {'mdidÈl mokï oduh'}
                                </Typography>
                                <Typography variant="body2" sx={cardContentDescriptionStyle}>
                                    {'mdidÈl fikiqka oduh yd tla frdla ù isák oyia ixLHd; i;amqreIkaf.a mskanr woyia ;:d.; iïud iïnqÿ mshdKka jykafiaf.a O¾uh yd úkhg wkql+,j msßisÿ fÉ;kdfjka hqla; j meyeÈ,s oelaulska hq;= j wêIaGdkh" ù¾hh" lemùu yd kqjKe;a;kaf.a .erySug ,la fkdjk wldrfhka l%shdjg kexùu ;=,ska Ydiksl yd iudchSh wjYH;djhka imqrd,Su fjkqfjka lghq;= lrkq ,nhs\''}
                                </Typography>
                            </CardContent>
                            <CardActions sx={cardActionsStyle}>
                                <Button onClick={() => {
                                    handleModalOpenClose()
                                    handleModalContent(modelContent)
                                }} size="small" sx={cardActionButtonStyle}>;jÿrg;a'''</Button>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <div className={'animated-card-border'}>
                        <Card sx={cardStyle}>
                            <CardMedia
                                sx={cardMediaStyle}
                                image={introduction01}
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {'mdidÈl jHdmD;s yd jevigyka'}
                                </Typography>
                                <Typography variant="body2" sx={cardContentDescriptionStyle}>
                                    {`mdidÈl fikiqka oduhg wh;a mQckSh iajdókajykafia,df.a u.fmkaùu hgf;a mdidÈl mokï oduhg wh;a .sys msßi;a tlaj miq.sh oYl follg wdikak ld,hla mqrdjg Ydiksl yd iudÔh jYfhka w;HdjYH" tfy;a ta i|yd lsisfjl=;a bÈßm;a fkdjQ" ienE wjYH;djhka ms,sn| fidhd n,d  isÿ lrkq ,enQ ish¿ jHdmD;s yd jevigyka óg wh;a fõ`}
                                </Typography>
                            </CardContent>
                            <CardActions sx={cardActionsStyle}>
                                <Button size="small" sx={cardActionButtonStyle}>;jÿrg;a'''</Button>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
