import FullScreenDialog from "../modal/fullScreenDialog";
import {Fragment, useContext, useState} from "react";
import {AppContext} from "../../../context/appContext";

export const LoginWrapper = (props) => {

    const [loginDialogOpen, setLoginDialogOpen] = useState(true);

    const {auth} = useContext(AppContext)

    const handleOnLoginSuccess = () => {
        setLoginDialogOpen(false);
        props.callback()
    };
    return (
        <Fragment>
            {auth && auth.user_name && (
                <>
                    {props.children}
                </>
            )}
            {auth == null || auth.user_name == null && (
                <FullScreenDialog
                    onSuccess={handleOnLoginSuccess}
                    open={loginDialogOpen}
                >

                </FullScreenDialog>
            )}

        </Fragment>
    )
}