import Footer from "./footer/Footer";
import {Box, Grid, Typography} from "@mui/material";
import {
  Email,
  Phone,
  Place,
} from "@mui/icons-material";
import {colors} from "../../../themes/themes";

const contactItemContainerStyle = {
  width: "25vw",
  backgroundColor: 'background.container',
  margin: "2vh 1vw 2vh 1vw",
  padding: "2vh 1vw 2vh 1vw",
  borderRadius: 0.3,
  minWidth: "250px",
  overflow: "hidden",
};
export const Contacts = () => {


  const mapContainterStyle = {
    margin: "2vw",
  };

  const iconContainer = {
    border: `3px dashed ${colors.text}`,
    width: "5rem",
    height: "5rem",
    borderRadius: "50vh",
    margin: "2vh auto 2vh auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const iconStyle = {
    margin: "auto",
    color: "secondary",
  };

  const lableStyle = {
    color: "accent",
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  return (
    <Box sx={{maxWidth:'100%',margin:'auto'}}>
      <Typography variant="h3" >úuiSï</Typography>

      <Grid >
        <Grid container
          item
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            maxWidth:'100%'
          }}
        >
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <Box data-aos="fade-left" sx={contactItemContainerStyle}>
              <Grid item sx={iconContainer}>
                <Place fontSize="large" sx={iconStyle} />
              </Grid>
              <Grid item>
                <Typography sx={lableStyle}>,smskh</Typography>
                <Typography variant="h6">
                  mdidÈl fikiqk" Y%S ir udj; j,a.u" ud;r
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                <Box data-aos="fade-in" sx={contactItemContainerStyle}>
                  <Grid item sx={iconContainer}>
                    <Email fontSize="large" sx={iconStyle} />
                  </Grid>
                  <Grid item>
                    <Typography sx={lableStyle}>B fï,a</Typography>
                    <Typography style={{ fontFamily: "Roboto" }} variant="h6">
                      pasadikasenasuna@gmail.com
                    </Typography>
                  </Grid>
                </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                <Box data-aos="fade-right" sx={contactItemContainerStyle}>
                  <Grid item fontSize="large" sx={iconContainer}>
                    <Phone sx={iconStyle} />
                  </Grid>
                  <Grid item>
                    <Typography sx={lableStyle}>ÿrl:k</Typography>
                    <Typography variant="h6">041-2238221</Typography>
                  </Grid>
                </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={mapContainterStyle}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19429.89546265374!2d80.51296381888035!3d5.946285567030781!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae13f63132a79c1%3A0x8071a5c185a5c89b!2sSenasuna!5e0!3m2!1sen!2slk!4v1636351646244!5m2!1sen!2slk"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Box>
      <Footer/>
    </Box>
  );
};
