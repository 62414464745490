export const colors = {
    primary: '#344039',
    secondary: '#535945',
    background: '#F2EDE4',
    text: '#8C8069',
    accent: '#808179',
    textLight: '#F2EDE4',
    containerBackgroundColor:'#fffcee',
    cardBorderColor: '#ffdc9d',
    navtext:'#ffffff'
};

export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
        background: {
            default: colors.background,
            container: colors.containerBackgroundColor,
        },
        textLight: {
            main: colors.textLight
        },
    },
    typography: {
        h1:{
            fontFamily: 'FMBasuru',
            color: colors.primary,
            fontWeight: '500',
        },
        h2:{
            fontFamily: 'FMBasuru',
            color: colors.primary,
            fontWeight: '500',
        },
        h3:{
            fontFamily: 'FMBasuru',
            color: colors.text,
            fontWeight: '500',
        },
        h4:{
            fontFamily: 'FMBasuru',
            color: colors.text,
            fontWeight: '500',
        },
        h5:{
            fontFamily: 'FMBasuru',
            color: colors.accent,
            fontWeight: '500',
        },
        h6:{
            fontFamily: 'FMBasuru',
            color: colors.accent,
            fontWeight: '500',
        },
        subtitle1:{
            fontFamily: 'FMBasuru',
            color: colors.navtext,
        },
        subtitle2:{
            fontFamily: 'FMBasuru',
            color: colors.accent
        },
        body1:{
            fontFamily: 'FMMalithi',//p tag
            color: colors.primary,
            fontSize: '1.2rem',
        },
        body2:{
            fontFamily: 'FMMalithi',//p tag
            color: colors.primary,
            fontSize: '1.2rem',

        },
        inherit:{
            fontFamily: 'FMMalithi',//p tag
        },


        // allVariants: {
        // //     //fontFamily: 'Ubuntu , \'sans-serif\'',
        //    fontFamily: 'FMMalithi', },

    },
    shape: {
        borderRadius: 10,
    },
    spacing: 8,
    props: {
        MuiTooltip: {
            arrow: true,
        },
        MuiAppBar: {
            color: 'inherit',
        },
    },
    overrides: {
        MuiAppBar: {
            colorInherit: {
                backgroundColor: '#689f38',
                color: '#fff',
            },
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 25,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 25,
                    textTransform: 'math-auto',
                    fontFamily: 'FMMalithi',
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    textTransform: 'math-auto',
                    fontFamily: 'Roboto'
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    border: 'none',
                    backgroundColor: colors.containerBackgroundColor,
                    borderRadius: '10px',
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    textTransform: 'math-auto',
                    fontFamily: 'Roboto'
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    textTransform: 'math-auto',
                    fontFamily: 'Roboto'
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
    }
};