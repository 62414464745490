import React, {createContext, useEffect, useState} from 'react'
import {getAccessToken} from "../utils/token-store";
import {parseJwt} from "../utils/app-utils";

export const AppContext = createContext()
export const AppContextProvider = (props) => {
    const [auth, setAuth] = useState(getAccessToken() ? parseJwt(getAccessToken()) : {})
    const [monasterySuggestions, setMonasterySuggestions] = useState([])
    const [foundationSuggestions, setFoundationSuggestions] = useState([])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModelContent] = useState("");

    const handleModalOpenClose = () => setModalOpen(!modalOpen);
    const handleModalContent = (content) => setModelContent(content);

    useEffect(() => {
        if (!modalOpen) {
            setModelContent("")
        }
    }, [modalOpen]);

    const authenticated = (data) => {
        setAuth(data)
    }

    const updateMonasterySuggestions = (data) => {
        setMonasterySuggestions(data)
    }
    const updateFoundationSuggestions = (data) => {
        setFoundationSuggestions(data)
    }
    return (
        <AppContext.Provider
            value={{
                auth,
                authenticated,
                monasterySuggestions,
                foundationSuggestions,
                updateMonasterySuggestions,
                updateFoundationSuggestions,
                modalOpen,
                handleModalOpenClose,
                handleModalContent,
                modalContent,
            }}>
            {props.children}
        </AppContext.Provider>
    )
}