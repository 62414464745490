import React from 'react';
import {Box, Grid, Typography, Card, CardContent, } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import CubeIcon from '@mui/icons-material/ViewInAr'; // Icon similar to the cubes in the image
import coreValues01 from '../../../assets/images/home/core-values/2020-01-04 පාසාදික සාමාජිකයන්ගේ සාකච්චාවන්.jpg';

const CoreValues = () => {

    const mainContainerStyle = {
        width: '80%',
        margin: '50px auto',
        borderRadius: '10px',
        padding: '50px',
        backgroundColor: 'background.container'
    }

    const cardStyle = {
        textAlign: 'center',
        width: "100%",
        backgroundColor: '#fffcee',
    };

    return (
        <Box sx={mainContainerStyle} alignItems="center" justifyContent="center">
            <Typography variant="h6" align="center" gutterBottom>
                yqfola jpkhlg iSud fkdù '''''
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
                mdidÈl uQ,sl o¾Ylhka
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                úoHdprK O¾uhka wNsj¾Okh jk whqßka ;:d.;hka jykafiaf.a ks¾u, Y%S ioaO¾uh wjfndaO lr .ekSfï uQ,sl fÉ;kdj
                fmroeß fldg f.k Ydiksl yd iudc fufyjfrys fhfok" kdhl;ajhg mßmQ¾Kj wkq.; jQ tluq;=lñka hq;= mdidÈl
                fikiqka ys iÕ msßi;a Wka jykafia,df.a weiqr ,;a oyfï fhfok mskaj;a msßilf.a;a iqixfhda.h úiska fuu o¾Yl
                fmrgqfldg .ksñka l%shd;aul fõ'
            </Typography>

            <Grid container spacing={2} alignItems="center" justifyContent="center">

                <Grid item xs={12} md={3}>
                    <Grid direction="column" container spacing={2} alignItems="center"
                          justifyContent="center">
                        <Grid sx={{width: "100%"}} item xs={12} md={3}>
                            <div className={'animated-card-border'}>
                                <Card variant="outlined" sx={cardStyle}>
                                    <CardContent>
                                        <MyLocationIcon fontSize="large"/>
                                        <Typography variant="h6">ksjerÈ oelau</Typography>
                                        <Typography variant="body2">ksjerÈ oelau</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid sx={{width: "100%"}} item xs={12} md={3}>
                            <div className={'animated-card-border'}>
                                <Card variant="outlined" sx={cardStyle}>
                                    <CardContent>
                                        <LocalFloristIcon fontSize="large"/>
                                        <Typography variant="h6">mdßY=oaO;ajh</Typography>
                                        <Typography variant="body2">mdßY=oaO;ajh</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid sx={{width: "100%"}} item xs={12} md={3}>
                            <div className={'animated-card-border'}>
                                <Card variant="outlined" sx={cardStyle}>
                                    <CardContent>
                                        <CubeIcon fontSize="large"/>
                                        <Typography variant="h6">Pkaoh</Typography>
                                        <Typography variant="body2">Pkaoh fyj;a leue;a;</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} md={6}>
                    <Box justifyContent="center" alignItems="center" height="auto" >
                        <img style={{height: 'auto',width:"100%", borderRadius: '15px'}} src={coreValues01} alt={""}/>
                    </Box>
                </Grid>


                <Grid item xs={12} md={3}>
                    <Grid direction="column" container spacing={2} alignItems="center"
                          justifyContent="center">
                        <Grid sx={{width: "100%"}} item xs={12} md={3}>
                            <div className={'animated-card-border'}>
                                <Card variant="outlined" sx={cardStyle}>
                                    <CardContent>
                                        <CubeIcon fontSize="large"/>
                                        <Typography variant="h6">Ñ;a;h</Typography>
                                        <Typography variant="body2">Ñ;a;h fyj;a wêIaGdkh</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid sx={{width: "100%"}} item xs={12} md={3}>
                            <div className={'animated-card-border'}>
                                <Card variant="outlined" sx={cardStyle}>
                                    <CardContent>
                                        <CubeIcon fontSize="large"/>
                                        <Typography variant="h6">ù¾hh</Typography>
                                        <Typography variant="body2">ù¾hh fyj;a W;aidyh</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        <Grid sx={{width: "100%"}} item xs={12} md={3}>
                            <div className={'animated-card-border'}>
                                <Card variant="outlined" sx={cardStyle}>
                                    <CardContent>
                                        <CubeIcon fontSize="large"/>
                                        <Typography variant="h6">úuxidj</Typography>
                                        <Typography variant="body2">úuxidj fyj;a kqj‚ka hqla;j hula l,ukdlrKh lsÍfï
                                            yelshdj</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CoreValues;
