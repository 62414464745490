import {Grid, Typography, useMediaQuery} from "@mui/material";
import {API_CONFIGS} from "../../../config";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide,} from 'swiper/react';
import {
    Autoplay,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectCube,
    EffectFade, EffectFlip,
    Pagination
} from "swiper/modules";

export const CommonTextContainer = (props) => {

    const {
        font,
        text,
        flexDirection,
        fontSize,
        fontStyle,
        lineHeight,
        textAlign,
        letterSpacing,
        wordSpacing,
        color,
        backgroundColor,
        margin,
        padding,
        borderLeft,
        borderRight,
        borderBottom,
        borderTop,
        width,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        wrapImage,
        wrapVideo,
        wrapSwiper,
        textIndent
    } = props.data

    const matches = useMediaQuery('(min-width:992px)');

    return (
        <div data-aos={props.transition}>
            <Grid direction="row" container spacing={2} alignItems={"center"}
                  sx={{
                      justifyContent: "space-between",
                      flexDirection: `${matches ? "row" : "column"}${flexDirection}`,
                      backgroundColor: backgroundColor,
                      padding: padding,
                      margin: margin,
                      width: "100%",
                      borderLeft: borderLeft,
                      borderRight: borderRight,
                      borderBottom: borderBottom,
                      borderTop: borderTop,
                      borderTopLeftRadius: borderTopLeftRadius,
                      borderTopRightRadius: borderTopRightRadius,
                      borderBottomLeftRadius: borderBottomLeftRadius,
                      borderBottomRightRadius: borderBottomRightRadius,
                  }}>
                <Grid item md={12} lg={width}>
                    <Typography
                        sx={{
                            fontSize: fontSize,
                            fontFamily: font,
                            fontStyle: fontStyle,
                            lineHeight: lineHeight,
                            textAlign: textAlign,
                            letterSpacing: letterSpacing,
                            wordSpacing: wordSpacing,
                            color: color,
                            textIndent: textIndent,
                            margin: 'auto',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {text}
                    </Typography>
                </Grid>
                {wrapImage && wrapImage.image && (
                    <Grid item md={12} lg={12 - width}>
                        <img
                            style={{
                                marginBottom: matches ? 0 : '1rem',
                                width: "100%",
                                shapeOutside: `${API_CONFIGS.fileBaseUrl}${wrapImage.image}`,
                                borderLeft: wrapImage.borderLeft,
                                borderRight: wrapImage.borderRight,
                                borderTop: wrapImage.borderTop,
                                borderBottom: wrapImage.borderBottom,
                                borderTopLeftRadius: wrapImage.borderTopLeftRadius,
                                borderTopRightRadius: wrapImage.borderTopRightRadius,
                                borderBottomLeftRadius: wrapImage.borderBottomLeftRadius,
                                borderBottomRightRadius: wrapImage.borderBottomRightRadius,
                            }}
                            src={`${API_CONFIGS.fileBaseUrl}${wrapImage.image}`}
                            alt={""}/>
                    </Grid>
                )}
                {wrapVideo && wrapVideo.url && (
                    <Grid item md={12} lg={12 - width}
                          sx={{
                              padding: "1rem",
                          }}
                    >
                        <iframe
                            src={wrapVideo.url}
                            titel="test"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                            style={{
                                width: "100%",
                                aspectRatio: "16 / 9",
                                borderLeft: wrapVideo.borderLeft,
                                borderRight: wrapVideo.borderRight,
                                borderTop: wrapVideo.borderTop,
                                borderBottom: wrapVideo.borderBottom,
                                borderTopLeftRadius: wrapVideo.borderTopLeftRadius,
                                borderTopRightRadius: wrapVideo.borderTopRightRadius,
                                borderBottomLeftRadius: wrapVideo.borderBottomLeftRadius,
                                borderBottomRightRadius: wrapVideo.borderBottomRightRadius,
                            }}
                        >

                        </iframe>
                    </Grid>
                )}
                {wrapSwiper && (
                    <Grid item md={12} lg={12 - width}>
                        <Swiper
                            effect={"slide"}
                            slidesPerView={wrapSwiper.slidesPerView}
                            speed={4000}
                            spaceBetween={wrapSwiper.spaceBetween}
                            centeredSlides={true}
                            freeMode={true}
                            loop={true}
                            autoplay={{
                                delay: Number(wrapSwiper.delay),
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, EffectCards, EffectFade, EffectCoverflow, EffectCreative, EffectCube, EffectFlip]}
                            style={{borderRadius: '15px'}}
                        >
                            {wrapSwiper.images && wrapSwiper.images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={`${API_CONFIGS.fileBaseUrl}${image}`}
                                         style={{borderRadius: '15px', width: "100%"}}
                                         alt={""}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}