import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, useMediaQuery} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import Typography from "@mui/material/Typography";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {forwardRef, useContext, useEffect, useMemo} from "react";
import {AppContext} from "../../../../context/appContext";
import {Link as RouterLink} from "react-router-dom";

export default function Footer() {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const matches = useMediaQuery('(min-width:875px)')

    const {monasterySuggestions,foundationSuggestions} = useContext(AppContext);

    const iconsList = {
        listStyle: 'none',
        display: 'flex',
        marginRight: '15px',
        paddingRight: '2rem',
        position: 'relative',
        verticalAlign: 'middle'
    }
    const icons = {
        cursor: 'pointer',
        verticalAlign: 'middle',
        marginRight: '25px',
    }
    const headLines = {
        width: '240px',
        margin: 0,
        paddingLeft: '20px',
        paddingTop: '20px',
        textAlign: 'left',
        fontSize: '1.5rem',
        fontFamily: ' FMMalithi',
        fontWeight: 'bold',
        color: '#393130',
        cursor: 'pointer',
    }
    const shortLine = {
        marginLeft: '2px',
        border: ' 1px solid #48435e',
        width: '50px',
        alignItems: 'start',
        transition: 'width 0.5s',
        borderRadius: '5px',
        "&:hover": {
            width: '0px',
        }

    }
    const ItemBox = {
        backgroundColor: "background.container",
        width: '20%',
        marginBottom: '3vw',
        // wordWrap: 'break-word'
    }
    const email ={
        "& .MuiListItemText-primary" : {
            fontFamily: "sans-herif",
        }
    }

    const mobileEmail = {
        "& .MuiTypography-body1": {
            fontFamily: "sans-herif",
        }
    }
    const d = new Date();
    let year = d.getFullYear();

    const footerItems =
        <Grid container sx={{
            margin: '0 auto',
            minWidth: '100%',
            justifyContent: "space-between",
            alignItems: "baseline",
            paddingLeft: '3%',
            paddingRight: '3%'
        }}>
            <Grid item size={{sm: 3, lg: 3}} sx={ItemBox}>
                <Typography variant="h5" gutterBottom sx={headLines}
                >
                    mdidÈl fikiqka
                    <hr style={shortLine}/>
                </Typography>
                <List>
                    {monasterySuggestions && monasterySuggestions.map((monastery, index) => (
                        <ListItemLink key={index} to={`/monasteries/${monastery.id}`} primary={monastery.name}/>
                    ))}

                </List>
            </Grid>
            <Grid item size={{sm: 3, lg: 3}} sx={ItemBox}>
                <Typography variant="h5" sx={headLines}>
                    mdidÈl mokï
                    <hr style={shortLine}/>
                </Typography>
                <List>
                    {foundationSuggestions && foundationSuggestions.map((foundation, index) => (
                        <ListItemLink key={index} to={`/monasteries/${foundation.id}`} primary={foundation.name}/>
                    ))}
                </List>
            </Grid>

            <Grid item size={{sm: 3, lg: 3}} sx={ItemBox}>
                <Typography variant="h5" gutterBottom sx={headLines}>
                    jHdmD;s iy jevigyka
                    <hr style={shortLine}/>
                </Typography>
                <List>
                    <ListItemButton>
                        <ListItemText  primary="mdidÈl O¾u foaYKd"/>
                    </ListItemButton>
                    <ListItemButton href="#">
                        <ListItemText  primary=" mdidÈl i;aldrl fikiqka"/>
                    </ListItemButton>
                </List>
            </Grid>
            <Grid item size={{sm: 3, lg: 3}} sx={ItemBox}>
                <Typography variant="h5" gutterBottom sx={headLines}>
                    úuiSï
                    <hr style={shortLine}/>
                </Typography>
                <List>
                    <ListItemButton sx={{marginRight: "10px"}}>
                        <PlaceIcon/>
                        <ListItemText
                            primary='mdidÈl fikiqk" Y%S ir udj;" j,a.u" ud;r'
                        />
                    </ListItemButton>
                    <ListItemButton href="#" sx={{marginRight: "10px"}}>
                        <EmailIcon/>
                        <ListItemText sx={email}

                            primary="pasadikasenasuna@gmail.com"
                        />
                    </ListItemButton>
                    <ListItemButton href="#">
                        <PhoneIcon/>
                        <ListItemText primary="041-2238221"/>
                    </ListItemButton>
                </List>
            </Grid>
        </Grid>

    function ListItemLink(props) {
        const {primary, to,} = props;

        const renderLink = useMemo(
            () =>
                forwardRef(function Link(itemProps, ref) {
                    return <RouterLink to={to} ref={ref} {...itemProps} role={undefined}/>;
                }),
            [to],
        );
        return (
            <li style={{padding: 0, margin: 0}}>
                <ListItemButton
                    selected={false}
                    component={renderLink}
                >
                    <ListItemText primary={primary}/>
                </ListItemButton>
            </li>
        );
    }

    const footerItemsSmall =
        <Box>
            <Accordion sx={{boxShadow: "none",}} expanded={expanded === 'panel1'}
                       onChange={handleChange('panel1')}>
                <AccordionSummary sx={{backgroundColor: 'background.container'}}
                                  expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                >
                    <Typography sx={{color: 'text.secondary'}}>mdidÈl fikiqka</Typography>

                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor: 'background.container'}}>
                    <List>
                        {monasterySuggestions && monasterySuggestions.map((monastery, index) => (
                            <ListItemLink key={index} to={`/monasteries/${monastery.id}`} primary={monastery.name}/>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{boxShadow: "none"}} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary sx={{backgroundColor: 'background.container'}}
                                  expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header"
                >
                    <Typography sx={{color: 'text.secondary'}}>mdidÈl mokï</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor: 'background.container'}}>
                    <List>
                        {foundationSuggestions && foundationSuggestions.map((foundation, index) => (
                            <ListItemLink key={index} to={`/monasteries/${foundation.id}`} primary={foundation.name}/>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{boxShadow: "none"}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary sx={{backgroundColor: 'background.container'}}
                                  expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel3bh-content"
                                  id="panel3bh-header"
                >
                    <Typography sx={{color: 'text.secondary'}}>jHdmD;s iy jevigyka</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor: 'background.container'}}>
                    <List
                        sx={{textAlign: 'left', fontSize: '1.839rem', maxWidth: '100%',}}
                    >
                        <ListItemButton>
                            <ListItemText primary="mdidÈl O¾u foaYKd"/>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="Ydiksl fiajd mokï"/>
                        </ListItemButton>
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
                       sx={{border: '0px '}}>
                <AccordionSummary sx={{backgroundColor: 'background.container'}}
                                  expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel4bh-content"
                                  id="panel4bh-header"
                >
                    <Typography sx={{color: 'text.secondary'}}>úuiSï</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor: 'background.container'}}>
                    <List sx={{textAlign: 'left', fontSize: '1.839rem', maxWidth: '100%',}}
                    >
                        <ListItemButton>
                            <PlaceIcon/>
                            <ListItemText primary={"mdidÈl fikiqk\" Y%S ir udj;\" j,a.u\" ud;r'"}/>
                        </ListItemButton>
                        <ListItemButton sx={mobileEmail}>
                            <EmailIcon/>
                            <ListItemText primary="pasadikasenasuna@gmail.com"/>
                        </ListItemButton>
                        <ListItemButton>
                            <PhoneIcon/>
                            <ListItemText primary="041-2238221"/>
                        </ListItemButton>
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    return (
        <Box sx={{ maxWidth: '100%',display: 'relative',backgroundColor: "background.container"}}>
            {/*social media line*/}
            <Box sx={{
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    alignItems: 'left',
                    width: '400px',
                    paddingTop: {xs: 1, sm: 2, md: 2},
                    marginLeft: '0 auto',
                }}><Typography variant="body1" sx={{fontFamily:"sans-serif"}}>Get connected with us on social networks:</Typography>

                </Box>
                <ul style={iconsList}>
                    <li style={icons}>
                        <FacebookIcon/>
                    </li>
                    <li style={icons}>
                        <GitHubIcon/>
                    </li>
                    <li style={icons}>
                        <TwitterIcon/>
                    </li>
                    <li style={icons}>
                        <InstagramIcon/>
                    </li>
                    <li style={icons}>
                        <GoogleIcon/>
                    </li>
                </ul>
            </Box>
            {matches ? footerItems : footerItemsSmall}
            <Box sx={{ maxWidth: '100%',backgroundColor: "background.default",justifyContent: "space-between",}}>
                <Typography
                    variant="subtitle2" sx={{fontFamily:"sans-serif"}}>
                    Copyright© {year} PASADIKA All Rights Reserved.
                </Typography>
            </Box>
        </Box>
    );
}
//  transform: "skewY(10deg)"
