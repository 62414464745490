import {makeStyles} from "@mui/styles";

export const commonStyles = makeStyles({
    cta: {
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    },
    textTruncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
});