import { Box } from "@mui/material";
import { CommonTextContainer } from "./commonTextContainer";
import { CommonImagesContainer } from "./CommonImagesContainer";
import { CommonTextListContainer } from "./CommonTextListContainer";
import { useEffect, useState } from "react";
import { getContentWrapContainerById } from "../../../client/contentWrapContainers/call";
import {CommonButtonFormatting} from "./commonButtonContainer";


export const ModelContentWrapContainer = ({containerId}) => {

    const [contents, setContents] = useState([])
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (containerId) {
            getContentWrapContainerById(
                result => {
                    setContents(result.content.contents)
                    setSettings(result.content.settings)
                },
                error => {
                },
                containerId,
            );
        }
    }, [])
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: settings ? settings.position : "center",
                }}
            >
                <Box sx={{
                    width: settings.width,
                    height: settings.height,
                    margin: settings.margin,
                    padding: settings.padding,
                    border: settings.border,
                    borderRadius: settings.borderRadius,
                    boxShadow: settings.boxShadow,
                    position: "relative",
                    backgroundImage: settings.backgroundImage,
                    backgroundColor: settings.backgroundColor,
                    overflow: "hidden",
                }}
                >
                    <Box
                        sx={{
                            display: 'flow-root',
                            padding: '1rem',
                            zIndex: 3,
                        }}
                    >
                        {contents && contents.map((item, index) => (
                            <Box key={index}>
                                {("TEXT" === item.type && (
                                    <CommonTextContainer
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("IMAGES" === item.type && (
                                    <CommonImagesContainer
                                        containerId={containerId}
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("TEXT_LIST" === item.type && (
                                    <CommonTextListContainer
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("BUTTON" === item.type && (
                                    <CommonButtonFormatting
                                        containerId={containerId}
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </div>
    )
}