import {AppBar, Box, IconButton, Toolbar,} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import {useEffect, useState, useContext} from "react";
import {StaticRouter} from "react-router-dom/server";
import {MemoryRouter, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import LoginMenu from "./loginMenu";
import {AppContext} from "../../../context/appContext";
import {ListItemLink} from "../router/listItemLink";
import logoAnimation from "../../../assets/images/pasadika-logo-giff.gif"

export default function MainAppBar(props) {

    const navigate = useNavigate()
    const {handleDrawerToggle} = props
    const {monasterySuggestions, foundationSuggestions, auth} = useContext(AppContext)
    const [wildMonasteries, setWildMonasteries] = useState([])
    const [monasteries, setMonasteries] = useState([])
    const [hospitalityMonasteries, setHospitalityMonasteries] = useState([])
    const [shasanikaServicesFoundations, setShasanikaServicesFoundations] = useState([])
    const [socialServicesFoundations, setSocialServicesFoundations] = useState([])

    useEffect(() => {
        let wildMon = [];
        let mon = [];
        let hosMon = [];
        monasterySuggestions.forEach(monastery => {
            if (monastery.type === "WMO") {
                wildMon.push(monastery);
            } else if (monastery.type === "MON") {
                mon.push(monastery);
            } else if (monastery.type === "HOM") {
                hosMon.push(monastery);
            }
        });
        setWildMonasteries(wildMon)
        setMonasteries(mon)
        setHospitalityMonasteries(hosMon)
    }, [
        monasterySuggestions
    ])

    useEffect(() => {
        let shaFound = [];
        let socialFound = [];
        foundationSuggestions.forEach(foundation => {
            if (foundation.type === "SHS") {
                shaFound.push(foundation);
            } else if (foundation.type === "SOS") {
                socialFound.push(foundation);
            }
        });
        setShasanikaServicesFoundations(shaFound)
        setSocialServicesFoundations(socialFound)
    }, [
        foundationSuggestions
    ])

    function Router(props) {
        const {children} = props;
        if (typeof window === 'undefined') {
            return <StaticRouter location="/home">{children}</StaticRouter>;
        }

        return (
            <MemoryRouter initialEntries={['/home']} initialIndex={0}>
                {children}
            </MemoryRouter>
        );
    }

    Router.propTypes = {
        children: PropTypes.node,
    };

    ListItemLink.propTypes = {
        icon: PropTypes.element,
        primary: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    };

    const handleLogout = () => {
        props.handleLogout()
    }
    const handleLogoClick = () => {
        navigate(`/home`);
    }

    return (
        <AppBar component="nav">
            <Box
                sx={{
                    width:"130px",
                    height:"130px",
                    borderRadius: "50%",
                    position: "fixed",
                    border: "5px solid #e2d1a6",
                    marginTop: "10px",
                    marginLeft: "170px",
                    backgroundColor: "#f9edd7",
                    ':hover': {
                        cursor: "pointer",
                    }
                }}
                onClick={handleLogoClick}
            >
                <img style={{width:"230px", marginLeft: "-50px"}} src={logoAnimation} alt={"logo"}/>
            </Box>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <MenuIcon/>
                </IconButton>
                <Box data-aos="fade-left"
                     sx={{
                         flexGrow: 1, display: {xs: 'none', sm: 'block'},
                         fontSize: '3rem',
                         padding: 0,
                         margin: 0,
                         fontFamily: "FMBasuru",
                         color: 'textLight.main',

                     }}
                > <Box onClick={handleLogoClick} style={{cursor: 'pointer', display: 'initial'}}>mdidÈl</Box>

                </Box>
                <Box sx={{display: {xs: 'none', sm: 'block',}}}>
                    <Box className={`nav-menu d-none d-lg-block`}>
                        <ul data-aos="fade-down">
                            <li className="drop-down"><p style={{margin: 'auto', padding: 0, color: 'white'}} href={"#"}>mdidÈl fikiqka
                                </p>
                                <ul>
                                    {monasteries != null && (monasteries.map((monastery, index) => (
                                        <ListItemLink key={index} to={`/monasteries/${monastery.id}`}
                                                      primary={monastery.name}/>
                                    )))}
                                    <li className="drop-down"><a href={"#"}>mdidÈl jk fikiqka</a>
                                        <ul>
                                            {wildMonasteries != null && (wildMonasteries.map((monastery, index) => (
                                                <ListItemLink key={index} to={`/monasteries/${monastery.id}`}
                                                              primary={monastery.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                    <li className="drop-down"><a href={"#"}>mdidÈl i;aldrl fikiqka</a>
                                        <ul>
                                            {hospitalityMonasteries != null && (hospitalityMonasteries.map((monastery, index) => (
                                                <ListItemLink key={index} to={`/monasteries/${monastery.id}`}
                                                              primary={monastery.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <ListItemLink isMain={true} to={`/deshana`} primary={`mdidÈl O¾u foaYKd`}/>
                            <ListItemLink isMain={true} to={`/events`} primary={`jHdmD;s iy jevigyka`}/>
                            <li className="drop-down"><p style={{margin: 'auto', padding: 0, color: 'white'}} href={"#"}>mdidÈl mokï</p>
                                <ul>
                                    <li className="drop-down"><a href={"#"}>Ydiksl fiajd mokï</a>
                                        <ul>
                                            {shasanikaServicesFoundations != null && (shasanikaServicesFoundations.map((foundation, index) => (
                                                <ListItemLink key={index} to={`/foundations/${foundation.id}`}
                                                              primary={foundation.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                    <li className="drop-down"><a href={"#"}>iudc i;aldrl mokï</a>
                                        <ul>
                                            {socialServicesFoundations != null && (socialServicesFoundations.map((foundation, index) => (
                                                <ListItemLink key={index} to={`/foundations/${foundation.id}`}
                                                              primary={foundation.name}/>
                                            )))}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <ListItemLink to={`/contact`} primary={`úuiSï`}/>
                        </ul>
                    </Box>
                </Box>
                {auth && auth.user_name ? <div data-aos="fade-right">
                    <LoginMenu
                        onClickLogoutButton={handleLogout}
                    />
                </div>:""}
            </Toolbar>
        </AppBar>
    )
}