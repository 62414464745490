import {Box, Grid} from "@mui/material";
import {CommonContainer} from "../container/commonContainer";
import {useEffect, useState} from "react";

import {EventCard} from "../../pages/events/eventCard";
import {PageContentWrapContainer} from "../container/pageContentWrapContainer";

export const PageBodyContent = (props) => {

    const [events, setEvents] = useState([])

    const [pageContentWrapContainers, setPageContentWrapContainers] = useState(props.containers);

    return (
        <Box sx={{marginTop:'100px'}}>
            <Box>
                {pageContentWrapContainers && (pageContentWrapContainers.map((container, index) => (
                    <Box key={index}>
                        <PageContentWrapContainer
                            containerId={container}
                            parentId={props.parentId}
                            parentType={props.parentType}
                        />
                    </Box>
                )))}
            </Box>
            {events && (
                <CommonContainer>
                    <Grid container spacing={0}
                          sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 0
                          }}
                    >
                        {events.map((event,index) => (
                            <EventCard key={index} event={event}/>
                        ))}
                    </Grid>
                </CommonContainer>
            )}
        </Box>
    )
}