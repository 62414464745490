import React, {useEffect, useState} from 'react';
import {Box, Button, Typography, Grid, Card, useMediaQuery} from '@mui/material';
import {styled} from '@mui/system';
import {loadEventSuggestions} from "../../../client/events/call";


const EventDetails = styled(Box)({
    flex: 1,
    marginLeft: '1rem',
    margin: 'auto',
    justifyContent: 'center',
});


export const EventList = () => {

    const [events, setEvents] = useState([]);
    const [foundationId, setFoundationId] = useState("");

    useEffect(() => {
        loadEventSuggestions(result => {
                setEvents(result.content)
            },
            null,
            null,
            8,
            null,
            {sortDirection: 'asc',foundationId:foundationId}
        );
    }, [foundationId])

    const mainContainerStyle = {
        width: '80%',
        margin: '50px auto',
        borderRadius: '10px',
        padding: '50px',
        backgroundColor: 'background.container'
    }
    const buttonStyleSmall = {lineHeight:'.8'}
    const buttonStyleLarge = {lineHeight:'1.75'}
    const matches = useMediaQuery('(min-width:1208px)');
    const buttonSizeMatch =matches ? buttonStyleLarge :buttonStyleSmall



    return (
        <Box sx={mainContainerStyle}>
            <Typography variant="h4"  textAlign="center" gutterBottom>
                jHdmD;s yd isoaëka
            </Typography>
            <Typography variant="body1" textAlign="center" mb={3}>
                {`mdidÈl mokï wdh;kh úiska isÿ lrkq ,enQ ish¿u Ydiksl yd iudc i;aldrhka ms<sn| f;dr;=/ oek .ekSu i|yd`}
            </Typography>

            <Box display="flex" justifyContent="center" mb={3}>
                <Button sx={buttonSizeMatch} variant="outlined" size="small" onClick={()=>setFoundationId("")} style={{margin: '0 0.5rem',}}>
                    ish,a,
                </Button>
                <Button sx={buttonSizeMatch} variant="outlined" size="small" onClick={()=>setFoundationId("60e981fd19ab8f0c602e0c9f")} style={{margin: '0 0.5rem',}}>
                    Ydik mqk¾Ôjk lghq;=
                </Button>
                <Button sx={buttonSizeMatch } variant="outlined" size="small" onClick={()=>setFoundationId("6174f48bf839f25242e3c2e9")} style={{margin: '0 0.5rem',}}>
                    yÈis iyk fiajd
                </Button>
                <Button sx={buttonSizeMatch } variant="outlined" size="small" onClick={()=>setFoundationId("60e9825e19ab8f0c602e0ca0")} style={{margin: '0 0.5rem',}}>
                    cd;sl mqk¾Ôjk lghq;=
                </Button>
                <Button sx={buttonSizeMatch } variant="outlined" size="small" onClick={()=>setFoundationId("60e9802019ab8f0c602e0c9e")} style={{margin: '0 0.5rem',}}>
                    mqKHuh lghq;=
                </Button>
                <Button sx={buttonSizeMatch } variant="outlined" size="small" onClick={()=>setFoundationId("60e9826d19ab8f0c602e0ca1")} style={{margin: '0 0.5rem',}}>
                    .s,ka irK fiajd
                </Button>
                <Button sx={buttonSizeMatch} variant="outlined" size="small" onClick={()=>setFoundationId("60e9828019ab8f0c602e0ca3")} style={{margin: '0 0.5rem',}}>
                    mdßißl lghq;=
                </Button>
            </Box>

            <Grid container spacing={4} justifyContent="center">
                {events && events.map((event, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <div className={'animated-card-border'}>
                            <Card>
                                <Grid container spacing={2}>
                                    <Grid sx={{margin: 'auto', justifyContent: 'left'}} item sm={12} md={4}>
                                        <img style={{
                                            height: '200px',
                                            backgroundColor: '#F2D6B3',
                                            borderRadius: '8px',
                                        }} src={event.thumbnail} alt={""}/>
                                    </Grid>
                                    <Grid sx={{margin: 'auto', justifyContent: 'center'}} item sm={12} md={6}>
                                        <EventDetails>
                                            <Box display="flex" alignItems="center">
                                                <Typography sx={{margin: 'auto',
                                                    justifyContent: 'center',}} variant="h6">{event.titleSinhala}</Typography>
                                            </Box>
                                            <Typography
                                                variant="body2">{`${event.createdDate} • ${event.location}`}</Typography>
                                            <Typography variant="body2" mt={1}>
                                                {event.description}
                                            </Typography>
                                        </EventDetails>
                                    </Grid>
                                    <Grid sx={{margin: 'auto', justifyContent: 'right'}} item sm={12} md={2}>
                                        <Button variant="contained" size="small" style={{margin: '0 0.5rem',}} >;jÿrg;a'''</Button>
                                        {/*<Button variant="contained" style={{margin: '0 0.5rem',}}>;jÿrg;a'''</Button>*/}
                                    </Grid>
                                </Grid>
                            </Card>
                        </div>
                    </Grid>
                    ))}
            </Grid>
        </Box>
    )
}
