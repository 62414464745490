import {Box, DialogTitle, Divider, Typography} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";

export const FoundationHome = () => {

    const paragraphStyle = {
        fontSize: '1.2rem',
        lineHeight: '1.5',
        marginBottom: '16px',
    };

    return (
        <>
            <DialogTitle id="scroll-dialog-title">
                <Typography
                    variant="h4"
                    sx={{
                        marginBottom: '16px',
                        marginTop: '16px',
                        textAlign: "center",
                    }}
                >
                    mdidÈl mokï wdh;k
                </Typography>
            </DialogTitle>
            <Divider/>
            <Box
                sx={{
                    maxWidth: '100%',
                    margin: "auto",
                    padding: "50px",
                    textAlign: "justify",
                    lineHeight: "1.5",
                }}
            >
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fuf,dj Wm; ,nk ukqIHhka iEu fokdgu uQ,sl wjYH;d ish,a, imqrd .ekSug tl yd iudk yelshdjka fkdue;' 
                    tfia jqj;a iEu i;ajfhl=u ;u Ôú;h mj;ajdf.k hdfï § mqoa.,sl" iudÔh fukau wdOHd;añl jYfhka bgq lr 
                    .ekSug n,dfmdfrd;a;=jk uQ,sl jQ wjYH;djhka we;'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fjfyi uykais ù ;uka hula Wmhd imhd f.k Ôj;a jk w;ru ;j;a wka whg Woõ Wmldr lsÍug leu;s fndfyda 
                    msßila wm iudch ;=,u Ôj;a fjhs' kuq;a tjeks jQ mqoa.hkaf.ka w;s nyq;rhlg ;ksju hï lghq;a;la ;ksju 
                    wdrïN lsÍug yd id¾:lj mj;ajdf.k hdfï yelshdjla fkdue;s nj È¾> ld,hla ;siafia isÿ l, jHdmD;s yd 
                    jevigyka ;=,ska ,o w;aoelSï yryd`} <span style={{color: "red"}}>mdidÈl fikiqka</span> {` oduh weiqr ,o i;amqreIhka wjfndaO lr .;a lreKls'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`hï hym;a jQ w¾:dkaú; lghq;a;lg odhl ù fuf,dj mrf,dj iq.;sh n,dfmdfrd;a;= jkakd jQ fndfyda msßilg 
                    ;uka l, hq;af;a l=ula o" flfia fldmuKlska" ljod" fld;kl § l, hq;= o hkak ms<sn|j meyeÈ,s oelaula yd 
                    l%ufõohla yd ksis ud¾f.damfoaYl;ajhla fkdue;s nj o wmf.a wjOdkhg ,la jQ lreKla jkafkah'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`tfukau tjeks fkdfhla wmyiq;djhkaf.ka fmf<kakka fjkqfjka ;u ld,h" Okh yd Y%uh lem lsÍug yelshdj 
                    we;s msßila wm iudchka ;=,u isák kuq;a hï lghq;a;l § th o ksjerÈ ÈYdjlg b,lal .; ù ;sfí o" idOdrK 
                    ld,hla ;=, th wjika l, yels lghq;a;la o" l, hq;a; fkdl, hq;a; l=ula o hkak ms<sn|j ksYaÑ; 
                    wjfndaOhla fkdue;sj l%shd;aul jk mqKH lghq;= wo j¾;udkh jk úg iudcfha fndfyda fihska oel.; 
                    yelsh' ;u ;ukaf.a mej;=ï yd wdl,am j,ska neyer fkdù yqfola mqKH lghq;= kdufhka isÿjk fulS 
                    jevigyka ;=,ska ;:d.;hka jykafia fmkajd jod< f,!lsl yd f,daflda;a;r wruqKq uqÿka muqKqjd .ekSu 
                    ms‚i bjy,a fõo hkak úuid ne,sh hq;= ld,h t<U we;'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fufia .sys meúÈ fomsßiu ksjerÈ o¾Ykhlska yd kdhl;ajhlska fukau lsisÿ úplaIK Ndjhlska f;drj 
                    lghq;= lrk wdldrh fndfyda ld,hla ;siafia `} <span style={{color: "red"}}>mdidÈl fikiqka</span> {` ys jdih lrk f.!rjkSh iajdóka 
                    jykafia,d ksy~j úu¾IKh lrñka miq jQ w;r w;HdjYH yd lsisfjla isÿ lsÍug bÈßm;a fkdjk tfy;a 
                    w;HjYHfhkau isÿúh hq;= lghq;= muKla y÷kd f.k b;du;a úuiSulg ,la lr n,ñka kqjKe;a;kaf.a 
                    .erySug o md;% fkdù ish¨‍ md¾Yajhka ys idOdrK;ajh ie,fik whqßka l%shd;aul lsÍu u.ska ,nd 
                    we;s id¾:l;ajh fya;=fjka talrdYS jQ Yla;su;a msßila `} <span style={{color: "red"}}>mdidÈl fikiqka</span> {` oduh jgd talrdYS ù we;'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`f,dj Ôj;ajk ish¨‍ i;ajhkaf.a woyia yd reÑ wreÑlï úúOdldr jkafkah' tf,iskau mkai,la fyda 
                    wdrKHhla weiqre lrk ieoeyej;=ka o tjeks úúOdldr woyia yd wdl,amj,ska hq;=j lghq;= lsÍu 
                    idudkH iajNdjhls' iudk woyia yd wdl,am we;s msßia fndfyda úg tlg tla jQ úg tu msßi 
                    lKavdhula fyda iuQyhla f,i ye¢kafjhs' tf,i lKavdhula f,i talrdYS ùfuka ;ks ;ksj isÿ l, 
                    fkdyels fndfyda hym;a foa l%shdjg kexúh yels fjhs'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fuf,i úúO jQ wruqKqj,ska hqla;j mdidÈl fikiqka oduh yd tla frdla ù isák oyia ixLHd;
                    i;amqreIkaf.a tlS mskanr woyia ;:d.; iïud iïnqÿ mshdKka jykafiaf.a O¾uh yd úkhg wkql+,j msßisÿ 
                    fÉ;kdfjka hqla; jQ meyeÈ,s oelaulska hq;= j wêIaGdkh" ù¾hh" lemùu yd kqjKe;a;kaf.a .erySug ,la 
                    fkdjk wldrfhka l%shdjg kexùu ;=,ska wmf.a wjOdkh fhduq úh hq;= yd i;H jYfhka u msysg ms<sirK
                    wjYH jk fkdfhla me;slhka wdjrKh lsÍu i|yd fuu`} <span style={{color: "red"}}>˜‍mdidÈl mokï˜‍</span> {`wdrïN lrk ,§'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {``}
                </Typography>



            </Box>
            <Divider/>
            <DialogActions>
                <Button onClick={() => {
                }}>Close</Button>
            </DialogActions>
        </>
    )
}