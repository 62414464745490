import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography, Grid, Button, useMediaQuery, CardMedia, CardContent, CardActions, Card} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {AppContext} from "../../../context/appContext";
import {API_CONFIGS} from "../../../config";
import {useNavigate} from "react-router-dom";

const FoundationSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {foundationSuggestions} = useContext(AppContext)

    const [religiousService, setReligiousService] = useState([])
    const [socialService, setSocialService] = useState([])

    useEffect(() => {
        let relService = [];
        let soService = [];
        foundationSuggestions.forEach(foundation => {
            if (foundation.type === "SHS") {
                relService.push(foundation);
            } else if (foundation.type === "SOS") {
                soService.push(foundation);
            }
        });
        setReligiousService(relService)
        setSocialService(soService)
    }, [
        foundationSuggestions
    ])

    const navigate = useNavigate()
    const handleOnClick = (id) => {
        navigate(`/foundations/${id}`)
    }

    const mainContainerStyle = {
        width: '80%',
        margin: '50px auto',
        borderRadius: '10px',
        padding: '50px',
        backgroundColor: 'background.container'
    }

    return (
        <Box sx={mainContainerStyle}>
            {/* Cards Section */}
            <Box sx={{margin: '60px auto', textAlign: 'center'}}>
                <Typography
                    variant="h4"
                    sx={{marginBottom: '16px',}}
                >
                    Ydiksl fiajd mokï
                </Typography>
                <Typography
                    variant="body1"
                    sx={{textAlign: 'center', margin: 'auto'}}
                >
                    {`Ydiksl wjYH;djhka fjkqfjka yd mqKHuh lghq;= fjkqfjka uQ,sl;ajh f.k lghq;= lrkq ,nk mokï`}
                </Typography>
            </Box>

            <Grid sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
                  container spacing={4}
            >
                {religiousService.map((foundation, index) => (
                    //////////

                            <Grid item xs={12} md={6} lg={4} key={index}>
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        padding: '24px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {/* Card Content */}
                                    <div className={'animated-card-border'}>
                                        <Card>
                                            <CardMedia
                                                sx={{height: 210}}
                                                image={API_CONFIGS.fileBaseUrl + '' + foundation.thumbnail}

                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {foundation.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {foundation.shortDescription}
                                                </Typography>
                                            </CardContent>
                                            <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                                                <Button onClick={() => handleOnClick(foundation.id)} variant={'outlined'} size="small" >;jÿrg;a'''</Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </Box>
                            </Grid>

                    /////////
                    // <Grid item xs={12} md={6} lg={4} key={index}>
                    //     <Box
                    //         sx={{
                    //             borderRadius: '8px',
                    //             padding: '24px',
                    //             textAlign: 'center',
                    //         }}
                    //     >
                    //         {/* Card Content */}
                    //         <div className={'animated-card-border'}>
                    //             <Card sx={{minHeight: '500px', maxWidth: '450px', margin: "auto",}}>
                    //                 <CardMedia
                    //                     sx={{height: 210}}
                    //                     image={API_CONFIGS.fileBaseUrl + '' + foundation.thumbnail}
                    //                     title="green iguana"
                    //                 />
                    //                 <CardContent>
                    //                     <Typography gutterBottom variant="h5" component="div">
                    //                         {foundation.name}
                    //                     </Typography>
                    //                     <Typography variant="body2">
                    //                         {foundation.shortDescription}
                    //                     </Typography>
                    //                 </CardContent>
                    //                 <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                    //                     <Button onClick={() => handleOnClick(foundation.id)} size="small" sx={{fontFamily: 'FMMalithi'}}>;jÿrg;a'''</Button>
                    //                 </CardActions>
                    //             </Card>
                    //         </div>
                    //     </Box>
                    // </Grid>
                    ))}
            </Grid>
            <Box sx={{margin: '60px auto', textAlign: 'center'}}>
                <Typography
                    variant="h4"
                    sx={{marginBottom: '16px',}}
                >
                    iudc fiajd mokï
                </Typography>
                <Typography
                    variant="body1"
                    sx={{textAlign: 'center', margin: 'auto'}}
                >
                    {`iudcuh yd cd;sluh jYfhka u;=j mj;sk úúO wjYH;djhka fjkqfjka lghq;= lsÍfï§ uQ,sl;ajh f.k lgh;= lrkq ,nk mokï`}
                </Typography>
            </Box>

            <Grid sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
                  container spacing={4}
            >
                {socialService.map((foundation, index) => (
                    //////

                            <Grid item xs={12} md={6} lg={4} key={index}>
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        padding: '24px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {/* Card Content */}
                                    <div className={'animated-card-border'}>
                                        <Card>
                                            <CardMedia
                                                sx={{height: 210}}
                                                image={API_CONFIGS.fileBaseUrl + '' + foundation.thumbnail}
                                                title="green iguana"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {foundation.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {foundation.shortDescription}
                                                </Typography>
                                            </CardContent>
                                            <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                                                <Button onClick={() => handleOnClick(foundation.id)} variant={'outlined'} size="small" >;jÿrg;a'''</Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                </Box>
                            </Grid>
                    //////

                    // <Grid item xs={12} md={6} lg={4} key={index}>
                    //     <Box
                    //         sx={{
                    //             borderRadius: '8px',
                    //             padding: '24px',
                    //             textAlign: 'center',
                    //         }}
                    //     >
                    //         {/* Card Content */}
                    //         <div className={'animated-card-border'}>
                    //             <Card sx={{minHeight: '500px', maxWidth: '450px', margin: "auto",}}>
                    //                 <CardMedia
                    //                     sx={{height: 210}}
                    //                     image={API_CONFIGS.fileBaseUrl + '' + foundation.thumbnail}
                    //                     title="green iguana"
                    //                 />
                    //                 <CardContent>
                    //                     <Typography gutterBottom variant="h5" component="div">
                    //                         {foundation.name}
                    //                     </Typography>
                    //                     <Typography variant="body2">
                    //                         {foundation.shortDescription}
                    //                     </Typography>
                    //                 </CardContent>
                    //                 <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                    //                     <Button onClick={() => handleOnClick(foundation.id)} size="small">;jÿrg;a'''</Button>
                    //                 </CardActions>
                    //             </Card>
                    //         </div>
                    //     </Box>
                    // </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default FoundationSection;
