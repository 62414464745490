import {Box} from "@mui/material";
import {CommonTextContainer} from "./commonTextContainer";
import {CommonImagesContainer} from "./CommonImagesContainer";
import {CommonTextListContainer} from "./CommonTextListContainer";
import {useEffect, useState} from "react";
import {getContentWrapContainerById} from "../../../client/contentWrapContainers/call";
import {CommonButtonFormatting} from "./commonButtonContainer";
import {ModelContentWrapContainer} from "./modelContentWrapContainer";
import MainModalDialog from "../modal/mainModal";
import Button from "@mui/material/Button";


export const PageContentWrapContainer = ({containerId}) => {

    const [contents, setContents] = useState([])
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (containerId) {
            getContentWrapContainerById(
                result => {
                    setContents(result.content.contents)
                    setSettings(result.content.settings)
                },
                error => {
                },
                containerId,
            );
        }
    }, [])

    const [modalOpen, setModalOpen] = useState(false);
    const [modelContentWrapContainer, setModelContentWrapContainer] = useState(null);
    const handleModalClose = () => {
        setModalOpen(false)
        setModelContentWrapContainer(null)
    }

    useEffect(() => {
        if (modelContentWrapContainer) {
            setModalOpen(true)
        }
    }, [modelContentWrapContainer]);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: settings ? settings.position : "center",
                }}
            >
                <MainModalDialog
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={""}
                    body={modelContentWrapContainer}
                    size={'xl'}
                />
                <Box sx={{
                    width: settings.width,
                    height: settings.height,
                    margin: settings.margin,
                    padding: settings.padding,
                    border: settings.border,
                    borderRadius: settings.borderRadius,
                    boxShadow: settings.boxShadow,
                    backgroundImage: settings.backgroundImage,
                    backgroundColor: settings.backgroundColor,
                }}
                >
                    <Box
                        sx={{
                            display: 'flow-root',
                            padding: '1rem',
                            zIndex: 3,
                        }}
                    >
                        {contents && contents.map((item, index) => (
                            <Box key={index}>
                                {("TEXT" === item.type && (
                                    <CommonTextContainer
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("IMAGES" === item.type && (
                                    <CommonImagesContainer
                                        containerId={containerId}
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("TEXT_LIST" === item.type && (
                                    <CommonTextListContainer
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("BUTTON" === item.type && (
                                    <CommonButtonFormatting
                                        containerId={containerId}
                                        data={item}
                                        transition={settings.transition}
                                    />
                                ))}
                                {("MODEL" === item.type && (
                                    <Box textAlign='center' sx={{margin: "0 auto"}}>
                                        <Button
                                            variant="outlined"
                                            aria-label="expand row"
                                            size="large"
                                            sx={{
                                                marginTop: '0rem',
                                                fontFamily: item.fontFamily,
                                                textTransform: 'lowercase',
                                            }}
                                            onClick={() => {
                                                setModelContentWrapContainer(<ModelContentWrapContainer
                                                    containerId={item.id}
                                                    parentId={containerId}
                                                    parentType={"Container"}
                                                />)
                                            }}
                                        >
                                            {item.title}
                                        </Button>
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </div>
    )
}