import {Box, Button} from "@mui/material";
import ScrollToTopButton from "./footer/ScrollToTopButton";
import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import {AppContext} from "../../../context/appContext";
import Disclaimer from "./disclaimer/disclaimer";

export default function WallPaper() {

    const {handleModalOpenClose, handleModalContent} = useContext(AppContext)
    const modelContent = <Disclaimer/>

    return (
        <Box
            style={{
                backgroundImage: `url("/home/web-landing-page.jpg")`,
                height: "100vh",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%"
            }}
        >
            <Box
                sx={{
                    margin: "auto 10% auto auto",
                    width: '30%',
                    alignItems: "right",
                    backgroundImage: `url("/home/paper.png")`,
                    padding: '120px',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Typography data-aos="fade-up"
                            sx={{
                                fontSize: '2rem',
                                color: '#164919',
                                textAlign: "justify",
                                margin: "auto",
                            }}
                            variant={"h2"}
                >
                    {`yd;ami isidrd me;sreKq m%{dj ;=,ska m%idoh cks; lrjkqjia''''`}

                </Typography>
                <p data-aos="fade-up"
                            style={{
                                textAlign: "justify",
                                margin: "auto",
                                fontFamily: "FMMalithi",
                                fontSize: "1.2rem",
                                color: "#15352f",
                                lineHeight: "1.5",
                            }}
                >
                    {`§¾> rd;‍‍%Skays yqfol,d jkdka;rhkays iajNdúl mßirh weiqre fldgf.k ;u wdOHd;añl mdßY=oaêh fyj;a 
                    Ôú;fha h:d¾:h fiùu ms‚i W;aidyj;a jk h;sjrhdkka jykafia,d msßila yd Wka jykafia,d weiqre fldgf.k hy .=K 
                    jvk oeyeñ msßila jvk ,o" Ndú; lrk ,o ;u wdOHd;añl Yla;sh kid fkdf.k tuÕska wjYH;d we;s wkqkag o msysgla 
                    jkakg h;ak orkd ksy~ l;djl ishqï iajrh mdidÈl kñka fufia m‍%lïmkh jk j.hs''''`}

                </p>
                <Box mt={4} textAlign="center">
                    <Button sx={{marginRight: '20px'}} onClick={() =>{
                        handleModalOpenClose()
                        handleModalContent(modelContent)
                    }} variant="outlined">fjí wvúh mßYS,khg fmr''' </Button>
                </Box>
                <ScrollToTopButton showBelow={250}/>
            </Box>
        </Box>
    );
};