import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography, Grid, Button, useMediaQuery, CardMedia, CardContent, CardActions, Card} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {AppContext} from "../../../context/appContext";
import {API_CONFIGS} from "../../../config";
import {useNavigate} from "react-router-dom";

export const MonasterySection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {monasterySuggestions} = useContext(AppContext)

    const [wildMonasteries, setWildMonasteries] = useState([])
    const [monasteries, setMonasteries] = useState([])
    const [hospitalityMonasteries, setHospitalityMonasteries] = useState([])

    useEffect(() => {
        let wildMon = [];
        let mon = [];
        let hosMon = [];
        monasterySuggestions.forEach(monastery => {
            if (monastery.type === "WMO") {
                wildMon.push(monastery);
            } else if (monastery.type === "MON") {
                mon.push(monastery);
            } else if (monastery.type === "HOM") {
                hosMon.push(monastery);
            }
        });
        setWildMonasteries(wildMon)
        setMonasteries(mon)
        setHospitalityMonasteries(hosMon)
    }, [
        monasterySuggestions
    ])

    const navigate = useNavigate()
    const handleOnClick = (id) => {
        navigate(`/monasteries/${id}`)
    }

    const mainContainerStyle = {
        width: '80%',
        margin: '50px auto',
        borderRadius: '10px',
        padding: '50px',
        backgroundColor: 'background.container'
    }

    return (
        <Box sx={mainContainerStyle}>
            {/* Section Heading */}
            <Box sx={{marginBottom: '48px', textAlign: 'center'}}>
                <Typography
                    variant="h4"
                    sx={{marginBottom: '16px',}}
                >
                    mdidÈl jk fikiqka
                </Typography>
                <Typography
                    variant="body1"
                    sx={{textAlign: 'center', margin: 'auto'}}
                >
                    O¾uh mqyqKqfjys h:d¾:h wkqj" jkdka;r iy l÷ wm úiska úYaj úoHd, f,i y÷kajk fohg iudk hehs mejiSu
                    w;sfYdala;shla fkdfõ' jkdka;r hkq" fyd¢ka wid oek .;a O¾uh ;u Ôú;h ;=,ska oel .;a NslaIQka
                    jykafia,dg muKla ;:d.;hka jykafiaf.a tu b.ekaùu ;u yoj; ;=,skau idlaId;a lr .ekSug h;ak ork
                    ia:dkhls'
                </Typography>
            </Box>

            {/* Cards Section */}
            <Grid sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
                  container spacing={4}
            >
                {wildMonasteries.map((monastery, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                        <Box
                            sx={{
                                borderRadius: '8px',
                                padding: '24px',
                                textAlign: 'center',
                            }}
                        >
                            {/* Card Content */}
                            <div className={'animated-card-border'}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 210}}
                                        image={API_CONFIGS.fileBaseUrl + '' + monastery.thumbnail}
                                        title="green iguana"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {monastery.name}
                                        </Typography>
                                        <Typography variant="body2">
                                            {monastery.shortDescription}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                                        <Button onClick={() => handleOnClick(monastery.id)} variant={'outlined'} size="small" >;jÿrg;a'''</Button>
                                    </CardActions>
                                </Card>
                            </div>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{margin: '60px auto', textAlign: 'center'}}>
                <Typography
                    variant="h4"
                    sx={{marginBottom: '16px',}}
                >
                    mdidÈl i;aldrl fikiqka
                </Typography>
                <Typography
                    variant="body1"
                    sx={{textAlign: 'center', margin: 'auto'}}
                >
                    {`iïnqÿ iiqfka Ñr meje;au fjkqfjka w;HdjYH fiajdjka lghq;= i|yd  Wmldr ms‚i l%shd;auljk fikiqka 
                    oduh" mdidÈl i;aldr fikiqka moaO;sh y÷kajdÈh yel`}
                </Typography>
            </Box>

            <Grid sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
                  container spacing={4}
            >
                {hospitalityMonasteries.map((monastery, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                        <Box
                            sx={{
                                borderRadius: '8px',
                                padding: '24px',
                                textAlign: 'center',
                            }}
                        >
                            {/* Card Content */}
                            <div className={'animated-card-border'}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 210}}
                                        image={API_CONFIGS.fileBaseUrl + '' + monastery.thumbnail}
                                        title="green iguana"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {monastery.name}
                                        </Typography>
                                        <Typography variant="body2">
                                            {monastery.shortDescription}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                                        <Button onClick={() => handleOnClick(monastery.id)} variant={'outlined'} size="small">;jÿrg;a'''</Button>
                                    </CardActions>
                                </Card>
                            </div>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{margin: '60px auto', textAlign: 'center'}}>
                <Typography
                    variant="h4"
                    sx={{marginBottom: '16px', fontFamily: 'FMBasuru'}}
                >
                    mdidÈl fikiqka
                </Typography>
                <Typography
                    variant="body1"
                    sx={{textAlign: 'center', margin: 'auto'}}
                >
                    {`mdidÈl jk fikiqka moaO;shg yd  mdidÈl i;aldr fikiqka moaO;shg wh;a fkdjk wfkl=;a fikiqka óg wh;a fõ`}
                </Typography>
            </Box>

            <Grid sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
                  container spacing={4}
            >
                {monasteries.map((monastery, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                        <Box
                            sx={{
                                borderRadius: '8px',
                                padding: '24px',
                                textAlign: 'center',
                            }}
                        >
                            {/* Card Content */}
                            <div className={'animated-card-border'}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 210}}
                                        image={API_CONFIGS.fileBaseUrl + '' + monastery.thumbnail}
                                        title="green iguana"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {monastery.name}
                                        </Typography>
                                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                            {monastery.shortDescription}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{margin: 'auto', justifyContent: 'center'}}>
                                        <Button onClick={() => handleOnClick(monastery.id)} variant={'outlined'} size="small" >;jÿrg;a'''</Button>
                                    </CardActions>
                                </Card>
                            </div>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
