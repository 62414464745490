import {Typography} from "@mui/material";
import {commonStyles} from "../../style/commonStyle";
import ctaImage from "../../../assets/images/home/cta/wild-monastery-nature.jpg"
import karapitiyaNightView from "../../../assets/images/home/cta/karapity-day-view.jpg"
import CommonGathaContainer from "../../ui/container/commonGathaContainer";
import {Cta} from "../../ui/cta/cta";
import {WildMonasteryCta} from "./wildMonasteryCta";
import {Contacts} from "./contacts";
import {KarapitiyaNightViewCta} from "./karapitiyaNightViewCta";
import IntroductionSection from "./introductionSection";
import {MonasterySection} from "./monasterySection";
import CoreValues from "./coreValues";
import * as React from "react";
import FoundationSection from "./foundationSection";
import {RecentEvents} from "./recentEvents";
import {NewsUpdatesSection} from "./latestNewsUpdates";
import {KeyEvents} from "./keyEvents";
import {AboutSection} from "./aboutSection";
import {EventList} from "./eventList";

export default function MainSection() {

    const classes = commonStyles()
    return (
        <>
            <CommonGathaContainer>
                <Typography variant={'h4'} color={'#535946'} mb={2} >
                    wmmld f; ukqfiiiq fh ckd mdr.dñfkd - w:dhx b;rd mcd ;Srfïjd kqOdj;s
                </Typography>
                <Typography variant={'body2'}>
                    ^iir ihqf¾& mrf;rg hkafkda ñksiqka w;frys ÿ¾,N fj;s ^iaj,am fofkls&" wkH jQ jeä fokd fuf;r wkqju ÿj;a
                </Typography>
            </CommonGathaContainer>
            <IntroductionSection/>
            <AboutSection/>
            <CoreValues/>
            <Cta
                image={ctaImage}
                content={<WildMonasteryCta/>}
            />
            <MonasterySection/>
            <Cta
                image={karapitiyaNightView}
                content={<KarapitiyaNightViewCta/>}
            />
            <FoundationSection/>
            <EventList/>
            <RecentEvents/>
            <NewsUpdatesSection/>
            <KeyEvents/>
            <Contacts/>
        </>
    )
}