import {Box, DialogTitle, Divider, Typography} from "@mui/material";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export default function Disclaimer() {

    const paragraphStyle = {
        fontSize: '1.2rem',
        lineHeight: '1.5',
        marginBottom: '16px',
    };

    const h5Style = {
        fontSize: '1.5rem',
        lineHeight: '1.5',
        marginBottom: '16px',
    };
    return (
        <>
            <DialogTitle sx={{
                backgroundColor: '#fff6d8'
            }} id="scroll-dialog-title">
                <Typography
                    variant="h4"
                    sx={{
                        marginBottom: '16px',
                        marginTop: '16px',
                        textAlign: "center",
                    }}
                >
                    mdidÈl fjí wvúh mßYS,khg fmr'''
                </Typography>
            </DialogTitle>
            <Divider/>
            <Box
                sx={{
                    maxWidth: '100%',
                    margin: "auto",
                    padding: "50px",
                    textAlign: "justify",
                    lineHeight: "1.5",
                    backgroundColor: '#fcfdf2',
                }}
            >

                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`l,K is;a we;s" i;amqreI .=K we;s" Ydiksl yd cd;sl ys;ldó wkka; wmßudk .sys meúÈ msßilf.a fkduiqre
                Wmldrhka mokï fldgf.k ˜‍mdidÈl moku˜‍ j;auka ;;ajhg mq¿,a úug ufydamldÍ úh' tu i;al%shjkag iyiïnkaO jQ
                W;=ukaf.a kï .ï lsisjla i|yka lsÍug W;aidyj;a fkdjkafka Tjqka flfrys we;s f.!rjfha wvqjla fyda l
                <
                .=K fkdie,lSu ksid fkdj fulS ish¿ lghq;= Ydiksl yd cd;sl wNsjDoaêh ms‚i iduQysl;aj yeÕSfuka hq;=j
                ieufokd tlaj isÿl< W;=ï i;al%shdjka ñi m%isoaêh ms‚i isÿl
                < udkh biau
                ;= lrjk ;j;a msxlï fkdjk neúks'
                fmfkk fkdfmfkk w;s uy;a msßila ;u Yla;s uÜgu Wmßu f,i lemlr fuu uyd Ydiksl i;al%shdjka ish,a, isÿ lr
                we;' ˜‍mdidÈl moku˜‍ tu .=Kj;=ka fj; ;u f.!rj mQ¾jl yDohdx.u ia;=;sh ksr;=re mqo lrisà'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fuu fjí wvúfha ish¿ f;dr;=re we;=,;a lr we;af;a ksjerÈ oelaulska hqla;j hï wfmalaIs; w¾:hla m%{djka;hkag ixcdkkh lsÍfï uQ,sl woyi fmroeß fldgf.kh' fuu fjì wvúh ksis wjfndaOhlska hq;=j mßYS,kh l, úg wm Tng lshkakg W;aidy lrk m‚jqvh ieoeyej;a kqjKe;s Tnf.a yoj;g oefkkq we; kqjKg f.dapr jkq we;'`}
                </Typography>
                <Typography
                    variant="h5"
                    sx={h5Style}
                >
                    {`kjlhkag ffu;%sfhka lrkq ,nk isysm;a lsßuhs'''`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fuu fjì wvúh mj;ajd f.k hkq ,nkafka mdidÈl oduh yd iïnkaO úúO md¾Yajhkaf.a myiqj ms‚i f;dr;=re fnod yeÍfï wruq‚ka iy mdidÈl mokï wdh;kh .ek f;dr;=re oek .ekSug Wkkaÿjla olajk ieoeye;shka yd wfkl=;a msßia fj; f;dr;=re ,nd §fï wruq‚ks'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`mdidÈl mokï wdh;khg lsisÿ mqoa.,fhl=f.a fyda wdh;khl fm!oa.,sl;ajhg ydks jk whqßka f;dr;=re" PdhdrEm fyda ùäfhda m< lsÍula isÿ lsÍug lsisÿ wfmalaIdjla fkdue;s nj ffu;%sfhka hq;=j isysm;a l, hq;=j we;' www'pasadika'lk ys i|yka hï f;dre;=rla fya;=fldgf.k ieoeyej;a Tnf.a fyda Tn okakd y÷kk fjkfll=f.a fyda wdh;khl fm!oa.,sl;ajhg ydks isÿjk nj jegfya kï lreKdlr wmj ta nj oekqj;a lsÍu isÿ lrkq ,nk uy;a Wmldrhls'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`fuu fjì wvúfha i|yka f;dr;=rej, hï jrola fyda m%udo fodaIhla wef;d;a tu jro fyda m%udo fodaIh isÿ l, md¾Yajh fj; th lreKdfjka hqla; fya;= iys;j u;=lr fmkajd§u ;=,ska ksjerÈ lr .ekSug wjia:djla ,nd §u W;=ï foaj .=Khls' tfia fkdue;sj tlS jro fyda m%udo fodaI iïnkaOfhka Tng we;s úfõpk fyda fkd.e,mSï wef;d;a ta nj m%:ufhkau wmyg fkdokajd fjk;a fjì wvúhl fyda m%isoaO udOHhl fyda fjk;a mqoa.,hka iuÕ úfõpkh lsÍu W;=ï ukqIH;ajh ,enQfjl=g fkd.e,fmk lghq;a;ls'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`lreKq ldrKd ksis whqßka jgyd fkdf.k hful= hula úfõpkh lsÍfuka isÿjkafka flf,iSug m;a fkdjQ is; kslrefka flf,iSu mu‚' fï jk úg isÿlr fkd.;a wl=i,hla isÿ lr .ekSu mu‚' `}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`msßisÿ oelaulg wkqrEmSj mdidÈl moku yd ine÷Kq iqmskaj;=ka úiska fulS ish¿ i;aldrhka yd mqKH lghq;= isÿlrk ,oafoa ;:d.;hka jykafiaf.a b.ekaùu l=ulao hkak wjfndaO lr.ekSug;a fof,dj jevms‚i;a" ;:d.; iïud iïnqÿ mshdKka jykafiaf.a Ydikh .ek wmeye§u we;s is;aj, meye§u we;s lrkq ms‚i;a meye§u we;s is;aj, meye§u jeä lrkq ms‚i;a fjkqjka ñi meye§u we;s is;aj, fkdmeye§u we;s lrkq ms‚i fyda meye§u we;s is;aj, meye§u wvq lrkq ms‚i fyda fkdfõ'`}
                </Typography>
                <Typography
                    variant="body1"
                    sx={paragraphStyle}
                >
                    {`tfia fyhska Tng mdidÈl fjì wvúfha i|yka f;dr;=re iïnkaOfhka ieoeyej;a Tng we;sjk .eg‍¿ wmf.kau úuiSu ;=,ska ieoeyej;a yd kqjKe;s Tnf.a is;g u;=jk .eg‍¿j,g ksis ms<shï ,nd f.k lh" jpkh" is; oukh lr .;a ;:d.; iïud iïnqÿ mshdKka jykafiaf.a wjjdo wkqYdikd wkqj lghq;= lrkakd jQ mqoa.,fhla jkq we;ehs hkak wmf.a úYajdihhs'`}
                </Typography>
            </Box>
            <Divider/>
            <DialogActions sx={{
                backgroundColor: '#fff6d8'
            }}
            >
                <Button onClick={() => {
                }}>Close</Button>
            </DialogActions>
        </>
    )
}